import Vue from "vue";
import VueRouter from "vue-router";
import store from '@/store/index';
import { menuList } from "@/api/Auth";

Vue.use(VueRouter);

const { isNavigationFailure, NavigationFailureType } = VueRouter

const routes = [
  {
    path: "/",
    name: "home",
    component: () => import(
      '@/views/Login'
    ),
  },
  {
    path: "/login",
    name: "login",
    component: () => import(
      '@/views/Login'
    ),
  },
  {
    path: "/index",
    name: "index",
    component: () => import(
      "@/views/Index"
    ),
    children: []
  },
  {
    path: "*",
    component: () => import(
      "@/views/404"
    ),
    meta: {
      title: '404'
    }
  },
];

const router = new VueRouter({
  routes,
})

const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => {
    if (isNavigationFailure(err, NavigationFailureType.redirected)) {
      err.to.path // '/admin'
      err.from.path // '/'
    } else {
      err
    }
  }
  )
}

const whiteUri = ['/login']
let asyncRouter

router.beforeEach((to, from, next) => {
  if (whiteUri.indexOf(to.path) !== -1) {
    next()
  }
  let auth = localStorage.getItem('accessToken');
  // let authInfo = localStorage.getItem('authInfo');
  let authRouter = processTrycatch(localStorage.getItem('routings'));
  if (!auth) {
    asyncRouter = null;
    next();
    return;
  }
  if (asyncRouter) {
    next()
  } else if (authRouter) {
    asyncRouter = authRouter;
    go(to, next)
  } else {
    menuList().then(async (res) => {
      asyncRouter = res.data.result;
      const menu = await store.dispatch('setRoutings');
      go(to, next)
    }).catch(err => {
      console.error(err)
    })
  }
})

function processTrycatch(jsonStr) {
  let obj = null
  try {
    obj = JSON.parse(jsonStr)
  } catch (e) {
    obj = jsonStr
  }
  return obj;
}

function go(to, next) {
  asyncRouter = filterAsyncRouter(asyncRouter)
  routes[2].children = [...asyncRouter]
  router.matcher = new VueRouter().matcher //match
  router.addRoutes(routes)
  next({ ...to, replace: true })
}

function filterAsyncRouter(routes) {
  return routes.filter((route) => {
    let component = route.path
    if (component) {
      route.component = view(component)
      if (route.children && route.children.length) {
        route.children = filterAsyncRouter(route.children)
      }
      return true
    }
  })
}

function view(path) {
  return function (resolve) {
    import(`@/views${path}`).then(mod => {
      resolve(mod)
    })
  }
}

export default router;
