import router from '@/router';
import axios from 'axios';
import { Message, MessageBox } from 'element-ui';
import { Release } from './ExternalApi';

let IP = "localhost";
let PORT = "10050";
let PATH = "/api";
// let URL = "http://" + IP + ":" + PORT + PATH;
let URL = "http://dytool.lingyundata.com" + PATH;
let TIMEOUT = 1000 * 20;

export const ip = IP;
export const port = PORT;
export const url = URL;
export const path = PATH;
export const time_out = TIMEOUT;

axios.defaults.timeout = TIMEOUT;

const loginRequest = (url, params) => {
  return axios({
    method: 'POST',
    url: `${URL}${url}`,
    headers: {
      'Content-Type': 'application/json',
      'client': 'admin',
    },
    data: params,
  })
}

const getRequest = (url, params) => {
  return axios({
    method: 'GET',
    url: `${URL}${url}`,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
      'client': 'admin',
    },
    params,
  })
}

const postRequest = (url, params) => {
  return new Promise((result, errMsg) => {
    axios({
      method: 'POST',
      url: `${URL}${url}`,
      headers: {
        'Content-Type': 'application/json',
        'client': 'admin',
      },
      data: params,
    }).then(res => {
      result(res);
    }).catch(err => {
      errMsg(err);
    })
  })
}

const customRequest = (method, url, headers, data) => {
  return new Promise((result, errMsg) => {
    axios({
      method,
      url,
      headers,
      data
    }).then(res => {
      result(res);
    }).catch(err => {
      errMsg(err);
    })
  })
}

// 导出xlsx
const downloadXlsx = (url, params) => {
  return axios({
    method: 'get',
    url: `${URL}${url}`,
    params: params,

    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
      'client': 'admin'
    },
    responseType: 'blob', // 一定要设置响应类型，否则页面会是空白pdf
  })
}

// http request 拦截器
axios.defaults.timeout = TIMEOUT;
axios.interceptors.request.use(
  config => {
    // 判断接口是否需要拦截
    if (Release.includes(config.url)) return config;
    let accessToken = localStorage.getItem('accessToken');
    config.headers.client = 'admin';
    if (accessToken) {  // 判断是否存在token，如果存在的话，则每个http header都加上token  ${token}
      config.headers.accessToken = `${accessToken}`;
    }
    return config
  }, error => {
    return Promise.reject(error)
  }
)

axios.interceptors.response.use(
  response => {
    statusCode(response.data.code, response.data.message);
    return response;
  },
  error => {
    if (error && error.response) {
      statusCode(response.data.code, response.data.message);
    } else {
      Message.error('请求服务器失败！');
    }
    return Promise.resolve(error);
  })

/**
 * 
 * @param {Number} code 
 * @param {*} message 
 */
const statusCode = (code, message) => {
  switch (code) {
    case 200:
      break;
    case 401:
      Message.error(`${message}`);
      reLogin()
      break;
    case 500:
      Message.error(`${message}(${code})!`);
      break;
    default:
  }
}

const reLogin = () => {
  let accessToken = null
  router.replace({
    path: '/login',
    query: {
      redirect: router.currentRoute.fullPath
    }
  });
}

export {
  loginRequest,
  getRequest,
  postRequest,
  customRequest,
  downloadXlsx,
  reLogin,
}