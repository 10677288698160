import { loginRequest, getRequest, postRequest } from "./Axios";

let systemController = "/admin/system/"
let appAuthUserController = "/admin/authUser/"
let authUserController = "/admin/user/";
let authPermissionController = "/admin/permission/";
let authRoleController = "/admin/role/"

// 登录接口
export const login = (params) => { return loginRequest(authUserController + "login", params) };

// 发送登录验证码
export const getPhoneCode = (username, type) => { return getRequest(systemController + "getPhoneCode", {
  username,
  type
}) };

export const register = (params) => { return postRequest(appAuthUserController + "register", params) };

export const bindSubAgent = (params) => { return postRequest(appAuthUserController + "bindSubAgent", params) };

export const info = () => { return getRequest(authUserController + "info") };

export const add = (params) => { return postRequest(authUserController + "save", params) };

export const edit = (params) => { return postRequest(authUserController + "update", params) };

export const loginOut = () => { return postRequest(authUserController + "loginOut") };

export const menuList = (params) => { return getRequest(authPermissionController + "findMenuList", params) };

export const list = (params) => { return getRequest(authUserController + "list", params) };

export const roleSelect = () => { return getRequest(authRoleController + "selectItem", null) };