<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
export default {
  name: "App",
  data() {
    return {};
  },
  created() {
    if (localStorage.getItem("accessToken")) {
      this.$store.dispatch("setRoutings");
    }
  },
};
</script>

<style lang="less">
body {
  height: 100%;
  width: 100%;
  box-sizing: border-box;
  padding: 0px;
  margin: 0px;
}
#app {
  height: 100%;
  width: 100%;
}
</style>