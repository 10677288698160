import { customRequest } from './Axios';

// let getShortLinkUrlApi = "https://shortlink.3ink.cn/connect/admin/shortLink/save";
let getShortLinkUrlApi = "http://localhost:14000/connect/admin/shortLink/save";

export const Release = [
  getShortLinkUrlApi,
];

export const getShortLinkUrl = (params) => { return customRequest("post", getShortLinkUrlApi, {
  'Content-Type': 'application/json',
  'client': 'admin',
}, {
  'url': params.linkUrl,
  'sourceType': 2,
}) };