import Vue from "vue";
import vuex from "vuex";
import { menuList } from '@/api/Auth';
Vue.use(vuex);
export default new vuex.Store({
  state: {
    user: false,
    routings: [],
  },
  mutations: {
    login(state, user) {
      state.user = user;
      localStorage.setItem("authInfo", user);
    },
    logout(state, user) {
      state.user = "";
      localStorage.setItem("authInfo", "");
    },
    SET_ROUTES: (state, payload) => {
      state.routings = payload;
    },
  },
  actions: {
    setRoutings({ commit, state }) {
      return new Promise((resolve, reject) => {
        menuList().then(res => {
          if (res.data.code == 200) {
            var data = res.data.result;
            commit('SET_ROUTES', data);
            localStorage.setItem('routings', JSON.stringify(data))
            resolve(res)
          }
        })
      })
    }
  }
});
